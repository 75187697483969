import i18next from "i18next";

// App unsaved state
export const state = () => ({
  country: null,
  locale: "en",
  categories: null,
  products: null,
  features: null,
  currentSite: null,
  maintenance: false,
  sites: [],
  isRtl: false,
  enableShop: false,
  translationData: [],
  isLoading: true,
});

export const actions = {
  async nuxtServerInit({ dispatch }, context) {
    await dispatch("loadCurrentSite", context);
    await dispatch("loadMenu", context);
    // await dispatch("loadSites");
    await dispatch("locales/fetchLocales", context);
    dispatch("fetchUser", context);
  },

  async fetchUser({ dispatch }, { $cookies }) {
    const token = $cookies.get("AuthTkn");

    if (token) {
      await dispatch("auth/fetch");
    }
  },

  async loadCurrentSite({ commit }, { params, $cookies }) {
    if (!params.country) {
      return;
    }
    const [country, locale] = params.country.split("-");

    try {
      const siteResponse = await this.$axios.get(`api/sites/${country}`);
      const jsonResponse = await this.$axios.get(
        `api/country-wise-locale-content/${country}/${locale}`
      );

      if (jsonResponse && jsonResponse.data) {
        const obj = {
          lng: locale,
          fallbackLng: "en",
          lazy: true,
          resources: jsonResponse.data,
        };
        i18next.init(obj);
        commit("SET_TRANSLATION_SOURCE", obj);
      } else {
        commit("SET_TRANSLATION_SOURCE", null);
      }

      commit("SET_CURRENT_SITE_INFO", siteResponse && siteResponse.data);
      commit("SET_COUNTRY", country);
      commit("SET_LOCALE", locale);
      commit("SET_RTL", ["he", "ar", "fa"].includes(locale));

      $cookies.set("CountryLocale", `${country}/${locale}`, {
        path: "/",
      });

      let token = this.$cookies.get("AuthTkn");

      if (token) {
        const expires_at = this.$cookies.get("expires_at");

        this.$cookies.set("AuthTkn", token, {
          expires: new Date(expires_at),
          secure: process.env.NODE_ENV === "production",
          path: `/${country}-${locale}`,
        });
      }
    } catch (error) {
      console.log(error);
      // console.error(error);
      this.$cookies.remove("CountryLocale");

      if (error.response && (error.response.status === 503 || error.response.status === 500)) {
        commit("SET_MAINTENANCE", true);
      } else {
        console.error(`🐛🐛🐛 Error connecting '/api/sites/${country}'`);
      }
    }
  },

  async loadTranslationJsonData({ commit }, params) {
    const [country, locale] = params.split("-");

    try {
      const jsonResponse = await this.$axios.get(
        `api/country-wise-locale-content/${country}/${locale}`
      );
      const obj = {
        lng: locale,
        fallbackLng: "en",
        lazy: true,
        resources: jsonResponse.data,
      };
      i18next.init(obj);
      commit("SET_TRANSLATION_SOURCE", obj);
    } catch (error) {
      this.$cookies.remove("CountryLocale");

      if (error.response.status === 503 || error.response.status === 500) {
        commit("SET_MAINTENANCE", true);
      } else {
        console.error(`🐛🐛🐛 Error connecting '/api/sites/${country}'`);
      }
    }
  },

  async loadSites({ commit, state }) {
    if (!state.country) {
      return;
    }

    try {
      const sites = await this.$axios.$get("api/sites");
      commit("SET_SITES", sites);
    } catch (error) {
      console.error("🐛🐛🐛 Error connecting '/api/sites'");
    }
  },

  async loadMenu({ commit, state }) {
    if (!state.country) {
      return;
    }

    try {
      const [productsResponse, categoriesResponse, featuresResponse] =
        await Promise.all([
          this.$axios.$get("/api/front/menu/products?type_id=1"),
          this.$axios.$get("/api/front/menu/categories/v2?type_id=2"),
          this.$axios.$get("/api/get-features"),
        ]);

      commit("SET_FEATURES", featuresResponse.data);
      commit("SET_PRODUCTS", productsResponse);
      commit("SET_CATEGORIES", categoriesResponse);
    } catch (error) {
      console.error(error);
    }
  },
};

export const mutations = {
  SET_MAINTENANCE(state, status) {
    state.maintenance = status;
  },
  SET_TRANSLATION_SOURCE(state, data) {
    state.translationData = data;
    state.isLoading = false;
  },
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_FEATURES(state, features) {
    state.features = features;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_COUNTRY(state, country) {
    state.country = country;
  },
  SET_CURRENT_SITE_INFO(state, currentSite) {
    state.currentSite = currentSite;
  },
  SET_LOCALE(state, locale) {
    state.locale = locale;
  },
  SET_SITES(state, sites) {
    state.sites = sites;
  },
  SET_RTL(state, bool) {
    state.isRtl = bool;
  },
};

export const getters = {
  getCountry(state) {
    return state.country;
  },
  getTranslationData(state) {
    return state.translationData;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getCurrentSite(state) {
    return state.currentSite;
  },
  getSites(state) {
    return state.sites;
  },
  getCurrentSiteSettings(state) {
    return (state.currentSite && state.currentSite.settings) || {};
  },
  getLocale(state) {
    return state.locale;
  },
  getMaintenance(state) {
    return state.maintenance;
  },
  getCategories(state) {
    return state.categories;
  },
  getProducts(state) {
    return state.products;
  },
  getFeatures(state) {
    return state.features;
  },
};
